import { Howl } from 'howler';
import WordTimingsService from './word_timings_service'

class AudioPlayerService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
    this.wordTimings;
    this.wordTimingsService = new WordTimingsService();
    this.soundPlaying = false;
    this.player = null; // Store the current sound object
    this.wordElements;
    this.activeWord;
    this.animationFrameId = null;
    this.wordElement;
    this.soundEl;
    this.speedOptions = {
      "slow": 0.8,
      "standard": 1.0,
      "fast": 1.5,
    }
    this.audioPool = [];
    this.maxPoolSize = 5;
    this.outputDeviceId = null;
  }

  playText(text, language, soundEl, otherOptions = {}, soundSyncEnabled = true) {

    if (this.soundPlaying) {
      this.stopSound(soundEl);
    }

    if (soundEl) {
      soundEl.classList.add('pulse');
    }

    this.fetchAudioData(text, language, otherOptions)
    .then(data => {
      soundEl.classList.remove('pulse');

      const { audioUrl, wordTimings } = data;

      if (otherOptions.chat_message_id) {
        const audioKey = `audioUrl_${otherOptions['chat_message_id']}`;
        localStorage.setItem(audioKey, audioUrl);
        soundEl.dataset.audioKey = audioKey;
      } else {
        soundEl.dataset.audioUrl = audioUrl;
      }

      if (wordTimings && soundSyncEnabled) {
        this.wordTimings = wordTimings;
        const playbackRate = this.speedOptions[otherOptions.speed] || 1.0;
        this.setupWordElements(this.wordTimings, this.wordElement, playbackRate);
        this.playSound(audioUrl, soundEl, playbackRate);
        this.setupSync();
      } else {
        this.playSound(audioUrl, soundEl, this.speedOptions[otherOptions.speed]);
      }
    })
    .catch(error => {
      console.error('Error playing audio:', error);
      this.stopLoading(soundEl);
    });
  }

  fetchAudioData(text, language, otherOptions) {
    const that = this;

    return fetch('/langua/stream_text_to_speech', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({ text: text, language: language, ...otherOptions })
    })
    .then(response => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let audioBytes = "";
      let alignmentData = { chars: [], charStartTimesMs: [], charEndTimesMs: [], charDurationsMs: []};
      let buffer = "";
 
      const processChunk = ({ done, value }) => {
        if (done) {
          const byteArray = new Uint8Array(audioBytes.length);
          for (let i = 0; i < audioBytes.length; i++) {
            byteArray[i] = audioBytes.charCodeAt(i);
          }
          const audioBlob = new Blob([byteArray.buffer], { type: 'audio/mp3' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const wordTimings = that.wordTimingsService.processNormalizedAlignment(alignmentData);

          return { audioUrl, wordTimings };
        }

        buffer += decoder.decode(value, { stream: true });
        let lines = buffer.split('\n');
 
        // Process each complete line as a JSON object
        for (let i = 0; i < lines.length - 1; i++) {
          const jsonString = lines[i].trim();
          if (jsonString) {
            try {
              const responseDict = JSON.parse(jsonString);
              const audioBytesChunk = window.atob(responseDict.audio_base64);
              audioBytes += audioBytesChunk;
 
              if (responseDict.alignment) {
                alignmentData.chars.push(...responseDict.alignment.characters);
                alignmentData.charStartTimesMs.push(...responseDict.alignment.character_start_times_seconds.map(time => time * 1000));
                alignmentData.charEndTimesMs.push(...responseDict.alignment.character_end_times_seconds.map(time => time * 1000));
                alignmentData.charDurationsMs = alignmentData.charEndTimesMs.map((endTime, i) => (endTime - alignmentData.charStartTimesMs[i]));
              }
            } catch (e) {
              console.error('Error parsing JSON chunk:', e);
            }
          }
        }
 
        // If the last line is incomplete, keep it in the buffer
        buffer = lines[lines.length - 1].trim();

        return reader.read().then(processChunk);
      };

      return reader.read().then(processChunk);
    });
  }

  playSound(url, soundEl, playbackRate = 1.0) {
    this.setupPlayer(url, soundEl, playbackRate);
    this.player.play();
  }

  setupAudio(wordElement, element, text = null, language = null, otherOptions = {}, soundSyncEnabled = true, playbackRate = 1.0) {
    try {
      this.wordElement = wordElement;

      if (this.soundPlaying) {
        if (this.soundEl == element) {
          this.togglePlayer();
          return;
        } else {
          this.stopSound(this.soundEl);
          this.soundEl = element;
        }
      } else {
        this.soundEl = element;
      }

      const audioKey = this.soundEl.dataset.audioKey;
      const audioUrl = this.soundEl.dataset.audioUrl;
      
      if (this.soundEl.dataset.wordTimings) {
        this.wordTimings = JSON.parse(this.soundEl.dataset.wordTimings);
      }

      if (audioKey) {
        const audioUrl = localStorage.getItem(audioKey);
        if (audioUrl) {
          if (this.wordTimings) {
            this.setupPlayerWithSync(audioUrl, this.soundEl, playbackRate);
          } else {
            this.playSound(audioUrl, this.soundEl, playbackRate);
          }
        }
      } else if (audioUrl != 'null' && audioUrl != undefined) {
        if (this.wordTimings) {
          this.setupPlayerWithSync(audioUrl, this.soundEl, playbackRate);
        } else {
          this.playSound(audioUrl, this.soundEl, playbackRate);
        }
      } else if (this.soundEl.dataset.audioData != 'null' && this.soundEl.dataset.audioData != undefined) {
        const audioData = JSON.parse(this.soundEl.dataset.audioData);
        const decodedAudioData = audioData.map((data) => {
          return window.atob(data);
        })
        const combinedAudioData = decodedAudioData.join('');
        const byteArray = new Uint8Array(combinedAudioData.length);

        for (let i = 0; i < combinedAudioData.length; i++) {
          byteArray[i] = combinedAudioData.charCodeAt(i);
        }
        const audioBlob = new Blob([byteArray.buffer], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);

        this.wordElement = this.soundEl.closest('.chat-message');

        if (this.wordElement !== null && this.wordElement !== undefined) {
          const chatMessageId = this.wordElement.dataset.chatMessageId;
          const audioKey = `audioUrl_${chatMessageId}`;
          localStorage.setItem(audioKey, audioUrl);
          this.soundEl.dataset.audioKey = audioKey;
        }

        this.setupPlayerWithSync(audioUrl, this.soundEl, playbackRate);
      } else {
        if (this.soundEl.dataset.chatMessageId) {
          otherOptions.chat_message_id = this.soundEl.dataset.chatMessageId;
        }
        this.playText(text, language, this.soundEl, otherOptions, soundSyncEnabled);
      }
    } catch (error) {
      console.error('Error setting up audio:', error); 
    }
  }

  setupPlayerWithSync(url, soundEl, playbackRate = 1.0) {
    this.setupWordElements(this.wordTimings, this.wordElement, playbackRate);
    this.setupPlayer(url, soundEl, playbackRate);
    this.setupSync();

    this.player.play();
  }

  setupPlayer(url, soundEl, playbackRate = 1.0) {

    if (this.soundPlaying) {
      this.stopSound(soundEl);
    }

    this.soundEl = soundEl;

    let availablePlayer = this.getAvailablePlayer(url, playbackRate);

    if (!availablePlayer) {
      if (this.audioPool.length >= this.maxPoolSize) {
        this.audioPool.shift().unload(); // Unload the oldest player
      }

      // preserve soundEl innerHTML
      const soundElIcon = soundEl.innerHTML;

      availablePlayer = new Howl({
        html5: true,
        src: [url],
        format: ['mp3'],
        rate: playbackRate,
        onplay: () => {
          if (this.outputDeviceId !== null) {
            const audioElement = availablePlayer._sounds[0]._node;

            if (typeof audioElement.setSinkId === 'function') {
              audioElement
                .setSinkId(this.outputDeviceId)
                .then(() => {
                  console.log('Audio output successfully redirected.');
                })
                .catch((error) => {
                  console.error('Failed to redirect audio output:', error);
                });
            }
          }

          this.soundPlaying = true;
          soundEl.innerHTML = '<i class="fa fa-pause"></i>';
          this.emitPlayStarted();
        },
        onend: () => {
          this.stopLoading(soundEl);
          soundEl.innerHTML = soundElIcon;
        },
        onpause: () => {
          soundEl.innerHTML = '<i class="fa fa-play"></i>';
        },
        onstop: () => {
          soundEl.innerHTML = '<i class="fa fa-play"></i>';
        }
      });

      this.audioPool.push(availablePlayer);
    }

    this.player = availablePlayer;
  }

  togglePlayer() {
    if (this.player === null) {
      return
    }

    if (this.player.playing()) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  toggleAudio(audioUrl, audioEl) {   
    try {
      if (this.soundPlaying) {
        if (this.soundEl == audioEl) {
          this.togglePlayer();
          return;
        } else {
          this.stopSound(this.soundEl);
          this.soundEl = audioEl;
        }
      } else {
        this.soundEl = audioEl;
      }

      if (audioUrl) {
        this.setupPlayer(audioUrl, audioEl);
        this.player.play();
      }
    } catch (error) {
      throw error;
    }
  }

  getAvailablePlayer(url, playbackRate) {
    for (let player of this.audioPool) {
      if (player._src === url && player._rate === playbackRate && !player.playing()) {
        return player;
      }
    }
    return null;
  }

  stopSound(soundEl) {
    if (this.player) {
      this.player.stop();
      this.player.unload();
    }
    this.stopLoading(soundEl);
  }

  stopLoading(soundEl) {
    this.soundPlaying = false;
    if (soundEl) {
      soundEl.innerHTML = '<i class="fa fa-play"></i>'
      soundEl.classList.remove('spin-loading', 'pulse');
    }
    this.player = null;
  }

  reset() {
    this.wordTimings = null;

    for (let word of this.wordElements) {
      word.style.fontWeight = "400";
      word.style.webkitTextStroke = "0px";
      word.style.color = "";  // Reset the color when the word is no longer active.
    }

    try {
      if (this.activeWord !== null && this.activeWord !== undefined) {
        this.activeWord.style.fontWeight = "400";
        this.activeWord.style.webkitTextStroke = "0px";
        this.activeWord.style.color = "";  // Reset the color when the word is no longer active.
        this.activeWord = null;
      }
    } catch (error) {
      console.error('Error resetting active wordt:', error);
    }

    this.activeWord = null;

    this.animationFrameId = null;
    this.soundPlaying = false;
  }

  setupSync() {
    this.player.on('play', () => {
      // Kickstart the animation loop
      this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
    });

    // Stop tracking when the audio is paused or ended
    this.player.on('pause', () => {
      cancelAnimationFrame(this.animationFrameId);
    });

    this.player.on('end', () => {
      cancelAnimationFrame(this.animationFrameId);
      this.reset();
    });

    this.player.on('playerror', (error) => {
      console.error('Error playing audio:', error);
      cancelAnimationFrame(this.animationFrameId);
      this.player.unload();
      this.reset();
    });

    this.player.on('loaderror', (error) => {
      console.error('Error playing audio:', error);
      cancelAnimationFrame(this.animationFrameId);
      this.reset();
    });
  }

  updateActiveWord(currentTime) {
    if (this.activeWord !== null && this.activeWord !== undefined) {
      const wordStartTime = parseFloat(this.activeWord.dataset.startTime);
      const wordEndTime = parseFloat(this.activeWord.dataset.endTime);
      if (currentTime >= wordStartTime && currentTime <= wordEndTime) {
        return true;
      }
    }

    for (let word of this.wordElements) {
      const wordStartTime = parseFloat(word.dataset.startTime);
      const wordEndTime = parseFloat(word.dataset.endTime);

      if (currentTime >= wordStartTime && currentTime <= wordEndTime) {
        if (this.activeWord && this.activeWord.id === word.id) {
          return true;
        }

        if (this.activeWord !== null && this.activeWord !== undefined) {
          this.activeWord.style.fontWeight = "400";
          this.activeWord.style.webkitTextStroke = "0px";
        }

        this.activeWord = word;
        this.activeWord.style.fontWeight = "500";
        this.activeWord.style.webkitTextStroke = "0.5px";

        break;
      } else {
        word.style.fontWeight = "400";
        word.style.webkitTextStroke = "0px";
        word.style.color = "";  // Reset the color when the word is no longer active.
      }
    }
  }

  handleTimeUpdate() {
    if (this.player === null) {
      return;
    }

    const currentTime = this.player.seek();
    this.updateActiveWord(currentTime);

    // Continue the animation loop
    this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
  }

  setupWordElements(wordTimings, wordElement, playbackRate = 1.0) {
    this.wordElements = Array.from(wordElement.querySelectorAll('.js-audio-word.word'));

    for (let i = 0; i < wordTimings.words.length; i++) {
      const startTime = wordTimings.word_start_times_ms[i];
      const duration = wordTimings.word_durations_ms[i];

      const wordElement = this.wordElements[i];
      if (wordElement) {
        wordElement.dataset.startTime = ((startTime / 1000) / playbackRate).toFixed(3); // Adjust for playback rate
        wordElement.dataset.endTime = (((startTime + duration) / 1000) / playbackRate).toFixed(3); // Adjust for playback rate
        wordElement.id = `word-${i}`;
      }
    }

    return this.wordElements;
  }

  updateSoundOutput(deviceId) {
    this.outputDeviceId = deviceId;
    for (let player of this.audioPool) {
      if(player !== null && player._sounds !== null && player._sounds !== undefined) {
        if (player._sounds.length > 0) {
          player._sounds[0]._node.setSinkId(deviceId);
        }
      }
    }
  }

  emitPlayEnded() {
    // Emit the playended event
    const event = new CustomEvent('chat:audioended');
    document.dispatchEvent(event);
  }

  emitPlayStarted() {
    // Emit the playended event
    const event = new CustomEvent('chat:audiostarted');
    document.dispatchEvent(event);
  }

}

export default AudioPlayerService;
